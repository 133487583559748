<template>
  <div :class="'Player container' + (overflow ? ' overflow' : '')">
    <div class="contentTab">
      <div
        :class="['menus', { active: active == item.groupValue }]"
        @click="menusHandle(item.groupValue)"
        v-for="(item, key) in menus"
        :key="key"
      >
        {{ item.groupText }}
      </div>
    </div>
    <div class="mainContent">
      <div class="head">
        <div>{{ $t("home.footballer") }}</div>
        <div>{{ $t("home.team") }}</div>
        <div>{{ active == 2 ? $t("home.assist") : $t("home.goal") }}</div>
      </div>
      <div class="content">
        <div class="row" v-for="(item, key) in playerData" :key="key">
          <div>
            <span class="index">{{ key + 1 }}</span>
            <div class="imgWrap">
              <img v-if="item.ph" :src="item.ph" alt />
              <img
                v-else
                class="defaultHead"
                :src="require('@/assets/imgs/comm/headDetault.svg')"
                alt
              />
            </div>

            <span>{{ item.pnm }}</span>
          </div>
          <div>{{ item.tnm }}</div>
          <div>{{ active == 2 ? item.ast : item.gs }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlayerStatList } from "@/service/Player";
import { mapState } from "vuex";

export default {
  name: "Player",

  data() {
    return {
      menus: [
        { groupValue: 1, groupText: this.$t("home.TopCcorer") },
        { groupValue: 2, groupText: this.$t("home.assists") },
      ],
      active: 1,
      playerData: [],
      fetchTimer: null,
    };
  },
  created() {
    this.getPlayerStatList();
    // this.startQueue();
  },
  // destroyed() {
  //   clearInterval(this.fetchTimer);
  //   this.fetchTimer = null;
  // },
  computed: {
    languageType() {
      return this.common.languageType;
    },
    overflow: {
      get() {
        return this.common.overflow;
      },
      set(val) {
        this.$store.commit("common/SET_DATA", {
          name: "overflow",
          data: val,
        });
      },
    },
    ...mapState(["common"]),
  },
  methods: {
    menusHandle(val) {
      this.active = val;
      this.getPlayerStatList();
    },
    buildData(data) {
      let filedText = this.active == 1 ? "gs" : "ast";
      this.playerData = data.sort(
        (curr, next) => next[filedText] - curr[filedText]
      );
    },
    startQueue() {
      if (this.fetchTimer) {
        clearInterval(this.fetchTimer);
        this.fetchTimer = null;
      }
      this.fetchTimer = setInterval(() => {
        this.getPlayerStatList();
      }, 3000);
    },
    getPlayerStatList() {
      getPlayerStatList({
        languageType: this.languageType,
        type: this.active,
      }).then((res) => {
        if (res.success) {
          this.buildData(res.data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Player {
  &.overflow {
    padding-top: 44px;
    .contentTab {
      position: fixed;
      top: 44px;
      z-index: 99;
      left: 0;
      right: 0;
      border-radius: 0;
    }
  }
  .contentTab {
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 20px 20px 0 0;
    .menus {
      height: 28px;
      line-height: 28px;
      flex: 1;
      text-align: center;
      background: #f4f4f4;
      font-size: 14px;
      color: #5a6375;
      &:first-child {
        border-radius: 100px 0 0 100px;
      }
      &:last-child {
        border-radius: 0 100px 100px 0;
      }
    }
    .active {
      background: #193ADD;
      color: #fff;
    }
  }
  .mainContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0;
    color: #131a3e;
    border-radius: 8px;
    margin: 8px;
    background: #f4f4f4;

    .head {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 16px;
      background: #fbfbfb;
      text-align: left;
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        width: 4px;
        left: 0px;
        top: 20%;
        bottom: 17.5%;
        border-radius: 0 5px 5px 0;
        background: #193ADD;
      }
      & > div {
        &:first-child {
          flex: 1;
          padding-left: 23px;
        }
        &:nth-child(2) {
          width: 100px;
        }
        &:nth-child(3) {
          width: 48px;
        }
      }
    }
    .content {
      // overflow: auto;
      flex: 1;
      .row {
        display: flex;
        align-items: center;
        height: 34px;
        padding: 0 16px;

        background: #fff;
        border-bottom: 0.5px solid #edeef1;
        & > div {
          img {
            width: 23px;
            height: 23px;
            border-radius: 50%;
          }
          &:first-child {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .imgWrap {
              display: inline-block;
              text-align: center;
              line-height: 22px;
              border: 1px solid #edeef1;
              overflow: hidden;
              border-radius: 50%;
              margin: 0 8px;
              width: 24px;
              height: 24px;
              vertical-align: middle;
              .defaultHead {
                width: 17.45px;
                height: 17.45px;
              }
            }
            & > img {
              margin: 0 8px;
            }
            span {
              font-weight: 500;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .index {
              font-family: "DIN";
              font-weight: 700;
              font-size: 12px;
              display: inline-block;
              vertical-align: middle;
              width: 15px;
              text-align: center;
            }
          }
          &:nth-child(2) {
            width: 100px;
            overflow: hidden;
            white-space: nowrap;
            padding-right: 10px;
            text-overflow: ellipsis;
          }
          &:nth-child(3) {
            width: 48px;
          }
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.dark{
  .Player {
  .contentTab {
    background: #151618;
    .menus {
      background: #292C30;
      color: #AAAFC9;
    }
    .active {
      background: #193ADD;
      color: #fff;
    }
  }
  .mainContent {
    color: #fff;
    background: #000;
    .head {
      background: #292C30;
    }
    .content {
      .row {
        background: #151618;
        border-bottom: 0.5px solid #2B2C30;
        & > div {
          &:first-child {
            .imgWrap {
              border: 1px solid #2B2C30;
            }
          }
        }
      }
    }
  }
}
}
</style>